document.addEventListener("DOMContentLoaded", () => {


  var exchangeFilter = document.querySelector('.exchange_filter');
  var rate = exchangeFilter ? exchangeFilter.querySelectorAll('.rate_option') : null;

  if (rate) {
      rate.forEach(function(rate) {
          rate.addEventListener('click', function(event) {
              event.preventDefault();
              var elements = exchangeFilter.querySelectorAll('.selected_blue');
              elements.forEach(function(element) {
                  element.classList.remove('selected_blue');
              });
              rate.classList.add('selected_blue');
          });
      });
  }

});
