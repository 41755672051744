export default function () {
    return {
        file: null,
        fileName: '',
        initFileInput() {
            document.getElementById('fileInput').addEventListener('change', this.handleFileChange);
        },
        selectFile() {
            document.getElementById('fileInput').click();
        },
        handleFileChange(event) {
            const files = event.target.files;
            if (files.length > 0) {
                this.file = files[0];
                this.fileName = this.file.name;
            }
        },
        handleFileDrop(event) {
            const files = event.dataTransfer.files;
            if (files.length > 0) {
                this.file = files[0];
                this.fileName = this.file.name;
            }
        },
        removeFile() {
            this.file = null;
            this.fileName = '';
            document.getElementById('fileInput').value = '';
        }
    };
}
