const closedFilter = document.querySelector('.closed_filter');

document.querySelectorAll('.select_type').forEach(function(item) {
    console.log('123');
  item.addEventListener('click', function() {
      console.log('456');
    this.nextElementSibling.classList.add('open');
  });
});

document.querySelectorAll('.whole_list').forEach(function(item) {
  item.addEventListener('click', function(event) {
    if (!event.target.closest('.whole_list input')) {
      this.classList.remove('open');
    }
  });
});

if (closedFilter) {
    closedFilter.addEventListener('click', function() {
        document.querySelectorAll('.whole_list').forEach(function(item) {
            item.classList.remove('open');
        });
    });
}
