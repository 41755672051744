if (document.querySelector('.btnControl')) {

  document.addEventListener("DOMContentLoaded", () => {
    const scrollBlock = document.querySelector('.btnControl');
    const scrollLinks = scrollBlock.querySelectorAll('.objBtn');
    const scrollPrevBtn = document.querySelector('.scrollingBtnPrev');
    const scrollNextBtn = document.querySelector('.scrollingBtnNext');

    scrollPrevBtn.addEventListener('click', () => {
      scrollBlock.scrollBy(-200, 0);
    });

    scrollNextBtn.addEventListener('click', () => {
      scrollBlock.scrollBy(200, 0);
    });

    scrollBlock.addEventListener('scroll', () => {
      const scrollLeft = scrollBlock.scrollLeft;
      const scrollWidth = scrollBlock.scrollWidth;
      const clientWidth = scrollBlock.clientWidth;

      if (scrollLeft === 0) {
        scrollPrevBtn.style.opacity = '0.5'; // скрыть кнопку "вперед", если блок прокручен в самое начало
      } else {
        scrollPrevBtn.style.opacity = '1';
      }

      if (scrollLeft + clientWidth === scrollWidth) {
        scrollNextBtn.style.opacity = '0.5'; // скрыть кнопку "назад", если блок прокручен в самый конец
      } else {
        scrollNextBtn.style.opacity = '1';
      }
    });
  });

};
