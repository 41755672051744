document.addEventListener('DOMContentLoaded', () =>{

  if(document.querySelector(".tab_btn")){

    const tabs = document.querySelectorAll('.tab_btn'),
      tabsContent = document.querySelectorAll('.offers_down'),
      tabsParent = document.querySelector('.offers_center');

    function hideTabContent() {
      tabsContent.forEach(item => {
        item.style.display = 'none'
      });

      tabs.forEach(item => {
        item.classList.remove('block_show')
      });
    }    
    
    function showTadContent (i = 0) {
      tabsContent[i].classList.add('block_show');
      tabsContent[i].style.display = 'flex';
      // tabs[i].classList.add('block_show');
    }

    function removeActivClassAll() {
      tabs.forEach(item => {
        item.classList.remove('activ')
      });
    }

    hideTabContent();
    showTadContent();

    tabsParent.addEventListener('click', (event) => {
      const target = event.target;
      if (target && target.classList.contains('tab_btn')) {
        tabs.forEach((item, i) => {
          if (target == item) {
            hideTabContent();
            showTadContent(i);
            removeActivClassAll();
            event.target.classList.add('activ');
          }
        });
      }
    });
  } else {
    
}

  
});