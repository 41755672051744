const menuBtn = document.querySelector('.menu_btn');
const mobMenu = document.querySelector('.mob_menu');

menuBtn.addEventListener('click', function () {
    menuBtn.classList.toggle('active');
    mobMenu.classList.toggle('show');
    document.body.classList.toggle('noscroll');

    const submenus = document.querySelector('.mob_menu').querySelectorAll('.submenu');
    submenus.forEach(function (submenu) {
        submenu.style.maxHeight = 0;
            submenu.classList.remove('open');
    });

    const chevrons = document.querySelector('.mob_menu').querySelectorAll('.bi-chevron-down');
    chevrons.forEach(function (chevron) {
        chevron.style.transform = "rotate(0deg)";
    });
}); 