export default function () {
    return {
        locale: 'ru',
        baseCurrency: 'KZT',
        currency: 'USD',
        sumFrom: '',
        sumTo: '',
        placeholderFrom: '',
        placeholderTo: '',
        exchangeRates: [],
        init: async function () {
            this.exchangeRates = await (await fetch('/api/v1/exchange-rates')).json();
            this.addPlaceholders();
        },
        convert: function (revert = false) {
            let currency = revert ? this.baseCurrency : this.currency;
            let baseCurrency = revert ? this.currency : this.baseCurrency;
            let sum = revert ? this.sumTo : this.sumFrom;

            let result = this._convert(baseCurrency, currency, sum);

            if (revert) {
                this.sumFrom = result;
            } else {
                this.sumTo = result;
            }
        },
        _convert: function (baseCurrency, currency, sum) {
            let fromRate = baseCurrency !== 'KZT' ? this.getRate(baseCurrency) : 1;
            let toRate = currency !== 'KZT' ? this.getRate(currency) : 1;
            let result = this.moneyUnFormat(sum) / fromRate * toRate;

            return result === 0 ? '' : new Intl.NumberFormat(this.locale).format(this.roundToDecimals(result, 2));
        },
        revert: function () {
            let baseCurrency = this.baseCurrency;
            this.baseCurrency = this.currency;
            this.currency = baseCurrency;

            this.addPlaceholders();

            this.convert();
        },
        getRate: function (currency) {
            return this.exchangeRates.data[currency].value
        },
        getPlaceholder: function (baseCurrency, currency) {
            return "1 " + baseCurrency + " = " + this._convert(baseCurrency, currency, 1) + " " + currency;
        },
        addPlaceholders: function () {
            this.placeholderFrom = this.getPlaceholder(this.baseCurrency, this.currency);
            this.placeholderTo = this.getPlaceholder(this.currency, this.baseCurrency);
        },
        changeCurrency: function (e) {
            let selectElement = e.target;
            let selectValue = selectElement.value;

            if (selectValue !== 'KZT' && this.baseCurrency === selectValue && this.currency === selectValue) {
                if (e.target.id === 'baseCurrency') {
                    this.currency = 'KZT';
                } else {
                    this.baseCurrency = 'KZT';
                }
            }

            if (this.baseCurrency === 'KZT' && this.currency === 'KZT') {
                if (e.target.id === 'baseCurrency') {
                    this.currency = 'USD';
                } else {
                    this.baseCurrency = 'USD';
                }
            }

            this.addPlaceholders();
            this.convert();
        },
        roundToDecimals: function (n, decimals) {
            let log10 = n ? Math.floor(Math.log10(n)) : 0,
                div = log10 < 0 ? Math.pow(10, decimals - log10 - 1) : Math.pow(10, decimals);

            return Math.round(n * div) / div;
        },
        moneyUnFormat: function (number) {
            number += '';
            return number.replace(/,/g, '.').replace(/(\s+)/g, '');
        },
        moneyFormat: function (input) {
            let formatted = new Intl.NumberFormat(this.locale).format(this.moneyUnFormat(input));
            let delimiter = input.toString().slice(-1);
            return delimiter === '.' || delimiter === ',' ? formatted + ',' : formatted;
        }
    }
}
