document.querySelector('.mob_menu').querySelectorAll('nav > ul > li > a').forEach(function(link) {
    link.addEventListener('click', function(e) {
        // e.preventDefault();
        let currentSubmenu = this.parentNode.querySelector('.submenu');

        this.closest('.mob_menu').querySelectorAll('.submenu').forEach(function(sm) {
            if (sm !== currentSubmenu) {
                sm.style.maxHeight = 0;
                setTimeout(() => {
                    sm.classList.remove('open');
                }, 500);
            }
        });

        if (currentSubmenu) {
            if (currentSubmenu.classList.contains('open')) {
                currentSubmenu.style.maxHeight = 0;
                setTimeout(() => {
                    currentSubmenu.classList.remove('open');
                }, 500);
                let chevron = this.querySelector('.bi-chevron-down');
                if (chevron) {
                    chevron.style.transform = "rotate(0deg)";
                }
            } else {
                currentSubmenu.classList.add('open');
                currentSubmenu.style.maxHeight = currentSubmenu.scrollHeight + "px";
                let chevron = this.querySelector('.bi-chevron-down');
                if (chevron) {
                    chevron.style.transform = "rotate(180deg)";
                }
            }
        }
    });
});
