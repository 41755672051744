const myBtn = document.querySelector('.btn-down');
const inputs = document.querySelectorAll('.myInput');

inputs.forEach(input => {
    input.addEventListener('.myInput', function () {
        if (this.value.trim() !== '') {
            myBtn.disabled = false;
        } else {
            myBtn.disabled = true;
        }
    });
});
