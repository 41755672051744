export default function (initialButtonType = null) {
    return {
        openLoanOrder: false,
        buttonType: initialButtonType,

        handleClick() {
            this.openLoanOrder = true;
            let pageName = document.querySelector('h1')?.innerText || 'H1 не найден';
            GTM.send('broker_start', this.buttonType, pageName);
        },
    };
}
