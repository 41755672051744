import {scrollIntoView} from "seamless-scroll-polyfill";

if (window.location.hash !== '' && window.location.hash === '#result') {

    const target = document.querySelector('.targetBtn');

    if (target) {
        scrollIntoView(target, {
                behavior: "smooth",
                block: "start",
            }
        );
    }
}

