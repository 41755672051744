export default function () {
    return {
        isPasswordVisible: false,
        countdown: 60,
        canResend: false,
        startRecoveryCountdown() {
            this.countdown = 60;
            this.canResend = false;
            this.isPasswordVisible = false;
            const interval = setInterval(() => {
                if (this.countdown <= 0) {
                    clearInterval(interval);
                    this.canResend = true;
                } else {
                    this.countdown--;
                }
            }, 1000);
        },
        formatTime(seconds) {
            const mins = Math.floor(seconds / 60);
            const secs = seconds % 60;
            return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        },
        resendPasswordRecovery() {
            this.canResend = false;
            this.startRecoveryCountdown();
            Livewire.emit('resendPasswordRecovery');
        },
        togglePasswordVisibility() {
            this.isPasswordVisible = !this.isPasswordVisible;
        }
    };
}
