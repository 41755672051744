import './ui/ui';
import './utilities/input-formatter';
import './services/service-comparison';
import './utilities/hash-scroll';
import converter from './components/converter';
import Alpine from 'alpinejs'
import mask from '@alpinejs/mask'
import Tool from './utilities/redirect';
import GTM from './services/gtm';
import './utilities/set-livewire-token';
import loanVerify from './components/loan-verify';
import './components/amount-range-slider/AmountRangeSlider';
import analytics from './services/analytics';
import contactsRequest from './components/contacts-request';
import initQRCode from './utilities/initQRCode.js';

window.GTM = new GTM;

Alpine.plugin(mask);
Alpine.plugin(converter);
Alpine.plugin(loanVerify);
Alpine.plugin(contactsRequest);

window.Alpine = Alpine;

Alpine.data('Tool', Tool);
Alpine.data('initQRCode', initQRCode);
Alpine.start();

analytics();

'use strict';

Livewire.onPageExpired(async (response, message) => {
    if (confirm('Срок действия этой страницы истек.\n' + 'Хотите обновить страницу?')) {
        try {
            await fetch("/regenerate-csrf");
        } catch (error) {
            console.error('Ошибка при обновлении CSRF токена:', error);
        }
        window.location.reload();
    }
})

