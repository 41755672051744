document.addEventListener("DOMContentLoaded", () => {
    Livewire.hook('message.sent', (message, component) => {

        const exceptions = ['disable-spinner', 'all-filters', 'loan-right-now', 'filter-button', 'loan-filter', 'loan-verify-modal', 'account-order-offers'];

        if (exceptions.includes(component.el.id) === false) {
            document.getElementById('spinnerLoad').style.display = 'flex';
        }

    })
});
window.addEventListener('spinnerLoadEnd', event => {
    document.getElementById('spinnerLoad').style.display = 'none';
})
