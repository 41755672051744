export default function () {
    return {
        busy: false,
        notification: '',
        fields: {
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            subject: '',
            content: '',
            'g-recaptcha-response': '',
        },
        errors: {},

        async send() {
            if (this.busy) return;
            this.busy = true;

            try {
                const token = this.getCsrfToken();

                if (!token) {
                    console.error("CSRF token not found in form.");
                    this.notification = "Token missing. Please refresh the page.";
                    return;
                }

                this.fields['g-recaptcha-response'] = getReCaptchaV3Response('contact_us_id');
                const response = await fetch("", {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        'X-CSRF-TOKEN': token
                    },
                    body: JSON.stringify(this.fields)
                });

                if (response.status === 429) {
                    this.notification = 'Too many requests.';
                    return;
                }

                if (!response.ok) {
                    await this.handleErrorResponse(response);
                    return;
                }

                const content = await response.json();
                this.notification = content.notification ?? '';
                this.clearForm();
            } catch (error) {
                console.error(error)
            } finally {
                refreshReCaptchaV3('contact_us_id','contact_us');
                this.busy = false;
            }
        },
        getCsrfToken() {
            let token = null;
            const tokenInput = this.$el.querySelector('input[name="_token"]');
            if (tokenInput && tokenInput.value) {
                token = tokenInput.value;
            } else {
                const metaToken = document.querySelector('meta[name="csrf-token"]');
                if (metaToken) {
                    token = metaToken.getAttribute('content');
                }
            }
            return token;
        },
        async handleErrorResponse(response) {
            try {
                const content = await response.json();
                this.errors = content.errors ?? {};
            } catch (jsonError) {
                console.error("Failed to parse error response:", jsonError);
                this.notification = "An error occurred. Please try again.";
            }
        },
        clearForm() {
            this.fields = {
                first_name: '',
                last_name: '',
                phone: '',
                email: '',
                subject: '',
                content: '',
                'g-recaptcha-response': '',
            };
            this.errors = {};
        },
    }
}
