const compares = document.querySelectorAll('.compare');
let sumInput = document.getElementById('sum-input'); 
let type = document.getElementsByName('loan_type')

compares.forEach((compare) => {

  compare.addEventListener('click', () => {
    const userSum = sumInput.value;
    const Type = type.value;
    dataLayer.push({
      'event': 'Choice_service_comparison',
      'service': type,
      'sum': userSum,
    }); 
      console.log(userSum)
      console.log(type)
  });
});

  
