import QRCode from "qrcode";

export default (id, url) => {
    return {
        init: function () {
            QRCode.toCanvas(document.getElementById(id), url, { width: 140 }, function (error) {
                if (error) console.error(error)
            })
        }
    }
}
