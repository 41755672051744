document.addEventListener('DOMContentLoaded', () =>{

  if(document.querySelector(".question")){

    const tabs = document.querySelectorAll('.question'),
      tabsContent = document.querySelectorAll('.answer'),
      tabsParent = document.querySelector('.faq_in');

    function hideTabContent() {
      tabsContent.forEach(item => {
        item.style.display = 'none'
      });

      tabs.forEach(item => {
        item.classList.remove('q-open')
      });
    }    
    

    function showTadContent (i = 0) {
      tabsContent[i].style.display = 'block';
    }

    function removeActivClassAll() {
      tabs.forEach(item => {
        item.classList.remove('q-open')
      });
    }

    tabsParent.addEventListener('click', (event) => {
      const target = event.target;
      if (target && target.classList.contains('question')) {
        tabs.forEach((item, i) => {
          if (target == item) {
            if (item.classList.contains('q-open')) {
              hideTabContent();
              removeActivClassAll();
            } else {
              hideTabContent();
              showTadContent(i);
              removeActivClassAll();
              event.target.classList.add('q-open');
            }
          }
        });
      }
    });

    document.addEventListener('click', (event) => {
      const target = event.target;
      const isQuestion = tabsParent.contains(target);
      if (!isQuestion) {
        hideTabContent();
        removeActivClassAll();
      }
    });
    
  } else {

  
    
}

  
});

