import  verificationComponent  from './verification';
import  passwordRecoveryComponent  from './passwordRecovery';
import  fileUploadComponent  from './fileUpload';
import  buttonHandler  from './buttonHandler';

export default function (Alpine) {
    Alpine.data('verificationComponent', verificationComponent);
    Alpine.data('passwordRecoveryComponent', passwordRecoveryComponent);
    Alpine.data('fileUploadComponent', fileUploadComponent);
    Alpine.data('buttonHandler', buttonHandler);
}
