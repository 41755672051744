document.addEventListener("DOMContentLoaded", () => {

    const openSelection = document.querySelector('.selection_bank'),
        closed = document.querySelector('.apply'),
        selectionWindow = document.querySelector('.selection_bank_block');

    if (openSelection) {
        openSelection.addEventListener('click', function (e) {
            e.preventDefault();
            openSelection.classList.add('selection_bank_open')
            selectionWindow.classList.add('visible');
        });
    }

    if (closed) {
        closed.addEventListener('click', function (e) {
            openSelection.classList.remove('selection_bank_open')
            selectionWindow.classList.remove('visible');
        });
    }

    var selectionBlock = document.querySelector('.selection_point');

    if (selectionBlock) {
        var obj = selectionBlock.querySelectorAll('.selection_obj');

        if (obj) {
            obj.forEach(function (obj) {
                obj.addEventListener('click', function (event) {
                    event.preventDefault();
                    var elements = selectionBlock.querySelectorAll('.selection_obj');
                    elements.forEach(function (element) {
                        element.classList.remove('btn_blue_border');
                    });
                    obj.classList.add('btn_blue_border');
                });
            });
        }
    }
});
