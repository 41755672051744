import 'toolcool-range-slider/dist/plugins/tcrs-binding-labels.min.js';
import 'toolcool-range-slider/dist/plugins/tcrs-generated-labels.min.js';
import 'toolcool-range-slider';

const appLocale = document.documentElement.lang ? document.documentElement.lang.split("_")[0] : 'ru';

var rangeValue = document.getElementById('inputSlider'),
    submitBtn = document.getElementById('submitBtn');

if (submitBtn) {
    document.getElementById("submitBtn").addEventListener("click", (e) => {
        window.location = '/' + appLocale + '/credits?amount=' + inputSlider.value.replace(/\s+/g, '') + '#result';
    });
}

if (rangeValue) {
    var emojiContainer = document.querySelector('.emoji'),
        editBtn = document.querySelector('.editBtn'),
        editImg = document.querySelector('.editImg'),
        inputField = document.querySelector('.valueInput'),
        slider = document.getElementById('inputrange'),
        displayValue = document.querySelector('#displayValue'),
        inputSlider = document.getElementById('inputSlider'),
        buttons = document.querySelectorAll('.hint'),
        isMouseDown = false;

    inputSlider.oninput = function () {
        formatValueAndUpdateSlider(this.value);
    };
    inputSlider.onfocus = function (e) {
        e.preventDefault();
        e.target.select();
    };

    slider.setAttribute('max', '125');
    slider.setAttribute('value', '15');
    const handleMouseDown = () => {
        isMouseDown = true;
    };
    const handleMouseUp = () => {
        isMouseDown = false;
    };

    slider.addEventListener('mousedown', handleMouseDown);
    slider.addEventListener('mouseup', handleMouseUp);
    slider.addEventListener('touchstart', handleMouseDown);
    slider.addEventListener('touchend', handleMouseUp);

    buttons.forEach((button) => {
        button.addEventListener('click', (e) => {
            e.preventDefault();
            const value = e.currentTarget.getAttribute('data-value');

            // if (value === 'max') {
            //   inputSlider.value = '∞';
            //   slider.value = slider.max;
            // } else {
            formatValueAndUpdateSlider(value);
            // }
        });
    });


    const updateEmoji = (value) => {
        emojiContainer.innerHTML = value;
    };

    let tempValue = '0';

    const setDisplayValue = (value) => {
        let result = '0';

        switch (true) {
            case value <= 20:
                if (value < 15) {
                    result = 150000;
                } else {
                    result = Math.floor(value) * 10000;
                }
                break;
            case (value > 20 && value <= 41):
                result = Math.floor((value - 21) / 3) * 50000 + 200000;
                break;
            case (value > 41 && value <= 67):
                result = Math.floor((value - 45) / 5) * 100000 + 600000;
                break;
            case (value > 67 && value <= 89):
                result = Math.floor((value - 68) / 5 + 1) * 1000000;
                break;
            case (value >= 90 && value <= 125):
                if (value < 114) {
                    result = Math.floor((value - 90) / 7 + 5) * 1000000;
                } else {
                    result = 8000000;
                }
                break;
            default:
                break;
        }

        // Отправка события 'updateLoanAmount' для обновления суммы в Livewire компоненте
        Livewire.emit('updateLoanAmount', result);

        // displayValue.innerHTML = result.toLocaleString('ru-RU');
        inputSlider.value = result.toLocaleString('ru-RU');
    };

    setDisplayValue(Number(slider.value));

    function handleSliderChange(evt) {
        if (isMouseDown) {
            setDisplayValue(Number(evt.detail.value));
        }
    }

    slider.addEventListener('change', handleSliderChange);

    const getReverseSliderValueFromInput = (value) => {
        value = Number(value.replace(/\D/g, ''));

        if (isNaN(value)) {
            console.error('Invalid input: not a number');
            return;
        }

        let sliderValue = 0;

        switch (true) {
            case value === 150000:
                sliderValue = 15;
                break;
            case value === 500000:
                sliderValue = 41;
                break;
            case value === 1000000:
                sliderValue = 66;
                break;
            case value === 5000000:
                sliderValue = 89
                break;
            case value === 8000000:
                sliderValue = 114
                break;
            default:
                // sliderValue = slider.max;
                break;
        }

        return sliderValue;
    };

    function formatValueAndUpdateSlider(inputValue) {
        var value = inputValue.replace(/\s/g, '').replace(/\D/g, '');

        if (value !== '') {
            // Отправка события 'updateLoanAmount' для обновления суммы в Livewire компоненте
            Livewire.emit('updateLoanAmount', value);
            value = Number(value).toLocaleString('ru-RU');
        }


        inputSlider.value = value;
        // console.log(Number(getReverseSliderValueFromInput(value.replace(/\D/g, ''))));
        slider.value = Number(getReverseSliderValueFromInput(value.replace(/\D/g, '')));
    }

    // hero slider ejomi
    var sumElem = document.getElementById('value');

    if (sumElem) {
        var observer = new MutationObserver(function (mutations) {
            var sumElemValue = sumElem.textContent;

            if (!sumElemValue) {
                return;
            }

            if (sumElemValue <= 15) {
                updateEmoji('😉');
            } else if (sumElemValue >= 15 && sumElemValue <= 20) {
                updateEmoji('😎');
            } else if (sumElemValue >= 20 && sumElemValue <= 41) {
                updateEmoji('😋');
            } else if (sumElemValue >= 41 && sumElemValue <= 67) {
                updateEmoji('🔥');
            } else if (sumElemValue >= 67 && sumElemValue <= 125) {
                updateEmoji('🚀');
            }
        });

        var config = {
            attributes: true,
            childList: true,
            subtree: true,
            characterData: true,
        };

        observer.observe(sumElem, config);
    }

}
