export default function (entangledCode) {
    return {
        countdown: 60,
        canResend: false,
        lockoutCountdown: 0,
        message: '',
        messageClass: '',
        code: ['','','','','',''],
        lockoutPeriod: 10, // window.config.lockoutPeriod,
        maxAttempts: 10, // window.config.maxAttempts,
        attempts: 0,
        codeError: false,
        startCountdown() {
            this.countdown = 60;
            this.canResend = false;
            const interval = setInterval(() => {
                if (this.countdown <= 0) {
                    clearInterval(interval);
                    this.canResend = true;
                } else {
                    this.countdown--;
                }
            }, 1000);
        },
        formatTime(seconds) {
            const mins = Math.floor(seconds / 60);
            const secs = seconds % 60;
            return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
        },
        moveToNext(event, index) {
            if (event.target.value.length === 1 && index < 5) {
                event.target.nextElementSibling.focus();
            }
            this.updateCode(index, event.target.value);
        },
        moveToPrevious(event, index) {
            if (event.key === 'Backspace' && event.target.value === '' && index > 0) {
                event.target.previousElementSibling.focus();
            }
            this.updateCode(index, event.target.value);
        },
        resendCode() {
            this.canResend = false;
            this.startCountdown();
            this.clearCodeInputs();
            this.message = 'Новый проверочный код был отправлен на ваш телефон.';
            this.messageClass = 'alert-info';
            this.codeError = false;
            Livewire.emit('resendCode');
        },
        clearCodeInputs() {
            this.code = ['','','','','',''];
            const inputs = document.querySelectorAll('.code-input');
            inputs.forEach(input => input.value = '');
            Livewire.emit('clearCode');
        },
        updateCode(index, value) {
            this.code[index] = value;
        },
        isComplete() {
            return this.code.every(value => value !== '');
        },
        isLockedOut() {
            return this.lockoutCountdown > 0;
        },
        startLockoutCountdown() {
            const interval = setInterval(() => {
                if (this.lockoutCountdown <= 0) {
                    clearInterval(interval);
                } else {
                    this.lockoutCountdown--;
                }
            }, 1000);
        },

        setupIOSOTPListener() {
            if (/iPhone|iPad/i.test(navigator.userAgent) && this.$refs.otpField) {
                this.$refs.otpField.addEventListener('input', (event) => this.handleIOSOTPInput(event));
            }
        },

        handleIOSOTPInput(event) {
            const code = event.target.value.trim();
            if (code.length === 6 && /^\d+$/.test(code)) {
                this.fillOTP(code);
            }
        },

        init() {
            // this.$watch('message', (value) => {
            //     this.message = value;
            // });

            if ('OTPCredential' in window && !navigator.userAgent.match(/(iPhone|iPad)/i)) {
                this.receiveOTP();
            }

            this.setupIOSOTPListener();

            const inputs = document.querySelectorAll('.code-input');

            inputs.forEach(input => {
                input.addEventListener('paste', (event) => this.handlePaste(event));
            });

            Livewire.on('resend-code', () => {
                this.startCountdown();
            });

            Livewire.on('code-verified', (data) => {
                this.message = data.message;
                this.messageClass = data.status === 'success' ? 'alert-success' : 'alert-danger';

                if (data.status === 'error') {
                    this.codeError = true;
                    this.attempts++;
                    if (this.attempts >= this.maxAttempts) {
                        this.lockoutCountdown = this.lockoutPeriod;
                        this.startLockoutCountdown();
                        this.attempts = 0;
                    }
                } else {
                    this.codeError = false;
                    if (data.status === 'success') {
                        Livewire.find(data.id).call('onVerificationSuccess');
                    }
                }
            });
        },

        async receiveOTP() {
            try {
                const content = await navigator.credentials.get({ otp: { transport: ['sms'] } });
                if (content && content.code) {
                    this.fillOTP(content.code);
                }
            } catch (error) {
                console.log('WebOTP API error', error);
            }
        },

        fillOTP(code) {
            const inputs = document.querySelectorAll('.code-input');
            code.split('').forEach((digit, index) => {
                if (inputs[index]) {
                    inputs[index].value = digit;
                    this.updateCode(index, digit);
                }
            });
            Livewire.emit('updateCode', this.code);
        },

        handlePaste(event) {
            const pasteData = event.clipboardData.getData('text');
            const digits = pasteData.split('').filter(char => /\d/.test(char));

            if (digits.length === 6) {
                const inputs = document.querySelectorAll('.code-input');
                inputs.forEach((input, index) => {
                    if (digits[index]) {
                        input.value = digits[index];
                        this.updateCode(index, digits[index]);
                    }
                });
                Livewire.emit('updateCode', this.code);
                event.preventDefault();
            }
        },
    };
}
