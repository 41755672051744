async function getLivewireToken() {
    const response = await fetch("/csrf");
    return await response.json();
}

window.addEventListener('livewire:load', function () {

    getLivewireToken().then((data) => {

        if (!data.token) return;

        let meta = document.head.querySelector('meta[name="csrf-token"]');

        if (meta) {
            meta.setAttribute('content', data.token);
        } else {
            let meta = document.createElement("meta");
            meta.name = "csrf-token";
            meta.id = "csrf-token";
            meta.content = data.token;
            document.head.appendChild(meta);
        }
    });
})
