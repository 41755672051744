const openPopUp = document.querySelector('.open_pop_up'),
      modWinFilter = document.querySelector('.mod_filter'),
      closedModWin = document.querySelector('.closed_filter'),
      body = document.querySelector('.body');

if (openPopUp) {
    openPopUp.addEventListener('click', function(e){
        e.preventDefault();
        modWinFilter.classList.add('open');
        body.classList.add('noscroll');
    });
}

if (closedModWin) {
    closedModWin.addEventListener('click', function(){
        if (modWinFilter) {
            modWinFilter.classList.remove('open');
        }
        body.classList.remove('noscroll');
    });
}
