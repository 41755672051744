/**
 * Добавляем новый метод в класс GTM
 * Название метода может быть таким же, как и название GTM события
 *
 * В HTML нужный метод запускаем через GTM.send()
 *
 * onclick="GTM.send('fast_selection_Credit');"
 *
 * или
 *
 * onclick="GTM.send('название_метода', параметр1, параметр2);"
 */
export default class GTM {
    send(name, ...args) {

        if (this.useGtm() === false) return;

        if (name && name in this && name !== 'send') {
            return this[name](...args);
        }

        return undefined;
    }

    useGtm() {
        if (typeof dataLayer === 'undefined') {
            console.log('GTM disabled!');
            return false;
        }

        return true;
    }

    test(event) {
        console.log(event.detail.total);
    }

    credit_form_filter(wire) {
        if (this.useGtm() === false) return;

        let amount = wire.amount;
        let term = wire.term;
        let loanType = wire.loanTypeName;

        dataLayer.push({
            'event': 'credit_form_filter',
            'sum': amount,
            'Term': term,
            'Type_Credit': loanType
        });

        console.log('credit_form_filter' + ' ' + amount + ' ' + term + ' ' + loanType);
    }

    fast_selection_Credit() {

        let amount = document.querySelector('#amount').value,
            term = document.querySelector('#term'),
            termValue = term.options[term.selectedIndex].text,
            loanType = document.querySelector('#category_id'),
            loanTypeValue = loanType.options[loanType.selectedIndex].text;

        if (this.useGtm() === false) return;

        dataLayer.push({
            'event': 'fast_selection_Credit',
            'sum': amount,
            'Term': termValue,
            'Type_Credit': loanTypeValue
        });

        console.log('sendGtmFast_selection_Credit' + ' ' + amount + ' ' + termValue + ' ' + loanTypeValue);
    }

    Choice_service_comparison_kredit() {
        if (this.useGtm() === false) return;
        let amount = document.querySelector('#online-credit #sum-input').value;

        dataLayer.push({
            'event': 'Choice_service_comparison',
            'service': 'credit',
            'sum': amount
        });

        console.log('sendGtmChoice_service_comparison_kredit' + ' ' + amount);
    }

    Choice_service_comparison_deposite() {
        if (this.useGtm() === false) return;
        let amount = document.querySelector('#online-depo #sum-input').value;

        dataLayer.push({
            'event': 'Choice_service_comparison',
            'service': 'deposite',
            'sum': amount
        });

        console.log('sendGtmChoice_service_comparison_deposite' + ' ' + amount);
    }

    Choice_service_comparison_card() {
        if (this.useGtm() === false) return;
        let amount = document.querySelector('#online-card #sum-input').value;

        dataLayer.push({
            'event': 'Choice_service_comparison',
            'service': 'card',
            'sum': amount,
        });

        console.log('sendGtmChoice_service_comparison_card' + ' ' + amount);
    }

    bank_card_form() {
        if (this.useGtm() === false) return;
        let card_type = document.querySelector('#card_type').value,
            currency = document.querySelector('#currency').value,
            advantages = document.querySelector('#advantages').value;

        dataLayer.push({
            'event': 'bank_card_form',
            'card_type': card_type,
            'currency': currency,
            'advantage': advantages
        });

        console.log('bank_card_form' + ' ' + card_type + ' ' + currency + ' ' + advantages);
    }

    bank_card_podat_zayavku(partnerName, cardName, cardType, currency) {
        if (this.useGtm() === false) return;
        let currentUrl = document.location.pathname,
            pageCategory = document.querySelector('.serviceHero--text h1').innerHTML;

        dataLayer.push({
            'event': 'bank_card_podat_zayavku',
            'bank_name': partnerName,
            'product_name': cardName,
            'сard_type': cardType,
            'Type_product': pageCategory,
            'Type_currency': currency,
            'Page_category': currentUrl
        });

        console.log('bank_card_podat_zayavku' + ' ' + partnerName + ' ' + cardName + ' ' + cardType + ' ' + currentUrl + ' ' + currency + ' ' + pageCategory);
    }

    favorable_credit_cart_top_10(bankName, cardType, currency) {
        if (this.useGtm() === false) return;
        let currentUrl = document.location.pathname;

        dataLayer.push({
            'event': 'favorable_credit_cart_top_10',
            'bank_name': bankName,
            'сard_type': cardType,
            'Type_currency': currency,
            'Page_category': currentUrl
        });

        console.log('favorable_credit_cart_top_10' + ' ' + bankName + ' ' + cardType + ' ' + currency + ' ' + currentUrl);
    }

    Selection_Credit(bankName, productName, typePartner) {
        if (this.useGtm() === false) return;
        let pageName = document.getElementsByTagName('h1')[0].innerText,
            inputSum = document.querySelector('#number').value,
            inputTerm = document.querySelector('#term'),
            inputTermValue = inputTerm.options[inputTerm.selectedIndex].text,
            inputLoanType = document.querySelector('#category_id'),
            inputLoanTypeValue = inputLoanType.options[inputLoanType.selectedIndex].text;

        dataLayer.push({
            'event': 'Selection_Credit',
            'bank_name': bankName,
            'product_name': productName,
            'Page_name': pageName,
            'type_partner': typePartner,
            'Type_sum': inputSum,
            'Type_term': inputTermValue,
            'Type_Сredit': inputLoanTypeValue
        });

        console.log('Selection_Credit' + ' - ' + bankName + ' - ' + productName + ' - ' + typePartner + ' - ' + pageName + ' - ' + inputSum + ' - ' + inputTermValue + ' - ' + inputLoanTypeValue);
    }

    favorable_credit_top_10(bankName, pageName) {
        if (this.useGtm() === false) return;
        dataLayer.push({
            'event': 'favorable_credit_top_10',
            'bank_name': bankName,
            'Page_name': pageName
        });

        console.log('favorable_credit_top_10' + ' ' + bankName + ' ' + pageName);
    }

    deposits_form() {
        if (this.useGtm() === false) return;
        let currency = document.querySelector('#currency').value,
            sum = document.querySelector('#number').value,
            currentUrl = document.location.pathname;

        dataLayer.push({
            'event': 'deposits_form',
            'sum': sum,
            'currency': currency,
            // 'percentage_rate': '{Передавать в писаную сумму ставки}',
            // 'bet_period': '{Передавать указанный срок ставки}',
            'Page_category': currentUrl
        });

        console.log('deposits_form' + ' ' + sum + ' ' + currency + ' ' + currentUrl);
    }

    deposits_zayavka(partnerName) {
        if (this.useGtm() === false) return;
        let currentUrl = document.location.pathname,
            currency = document.querySelector('#currency'),
            currencyValue = currency.options[currency.selectedIndex].text,
            sum = document.querySelector('#number').value;

        dataLayer.push({
            'event': 'deposits_zayavka',
            'bank_name': partnerName,
            'sum': sum,
            'currency': currencyValue,
            // 'percentage_rate': percentage_rate,
            // 'bet_period': '{Передавать срок ставки}',
            'Page_category': currentUrl
        });

        console.log('deposits_zayavka' + ' ' + sum + ' ' + partnerName + ' ' + currentUrl + ' ' + currencyValue);
    }

    favorable_deposits_top_10(bankName, term, percent) {
        if (this.useGtm() === false) return;
        let currentUrl = document.location.pathname,
            sum = document.querySelector('#number').value;
        // percent = percent,
        // term = term;

        dataLayer.push({
            'event': 'favorable_deposits_top_10',
            'bank_name': bankName,
            'sum': sum,
            'percentage_rate': percent,
            'bet_period': term,
            'Page_category': currentUrl
        });

        console.log('favorable_deposits_top_10' + ' bank name: ' + bankName + ' sum: ' + sum + ' url: ' + currentUrl + ' percent: ' + percent + 'term: ' + term);
    }

    bank_partners_page(bankName) {
        if (this.useGtm() === false) return;
        dataLayer.push({
            'event': 'bank_partners_page',
            'bank_name': bankName
        });
        console.log('favorable_deposits_top_10' + ' bank name: ' + bankName + ' sum: ' + sum + ' url: ' + currentUrl + ' percent: ' + percent + 'term: ' + term);
    }

    textpage_sidebar(bankName, Type_product, Type_filter) {

        if (this.useGtm() === false) return;

        dataLayer.push({
            'event': 'textpage_sidebar',
            'bank_name': bankName, // (acredit)
            'Type_filter': Type_filter, // Микрокредит
            'Type_product': Type_product // loan
            // 'serial_number': '{Передавать порядковый номер, здесь без Владислава никак}'
        });

        console.log('textpage_sidebar' + ' bank name: ' + bankName + ' Type_filter: ' + Type_filter + ' Type_product: ' + Type_product);
    }

    nothing_found(wire) {

        if (this.useGtm() === false) return;

        let bankName = wire.partnerName;
        let type_product = wire.loanTypeName;
        let sum = wire.amount;
        let term = wire.term;
        let page_category = document.location.pathname;

        dataLayer.push({
            'event': 'nothing_found',
            'type_product': type_product,
            'sum': sum,
            'term': term,
            'page_category': page_category
        });

        console.log('nothing_found' + ' type_product: ' + type_product + ' sum: ' + sum + ' term: ' + term + ' page_category:' + page_category);
    }

    // BROKER EVENTS

    application_submit(pageCategory, method, typePartner, partnerName, productName, affiliation) {
        console.log(this.useGtm());
        if (this.useGtm() === false) return;
        console.log("Starting application_submit method");

    const pageCategoryValue = pageCategory,
        typeMethod = method,
        typePartnerValue = typePartner,
        partnerNameValue = partnerName,
        productNameValue = productName,
        pageNameValue = document.getElementsByTagName('h1')[0]?.innerText || '',
        inputLoanTypeElement = document.querySelector('#category_id'),
        typeSumValue = document.querySelector('#number')?.value || '',
        typeTermValue = document.querySelector('#term')?.value || '',
        affiliationValue = affiliation,
        currency = document.querySelector('#currency');

    let typeProductValue = '',
        currencyValue = '';

    if (inputLoanTypeElement && inputLoanTypeElement.options && inputLoanTypeElement.selectedIndex >= 0) {
        typeProductValue = inputLoanTypeElement.options[inputLoanTypeElement.selectedIndex].text;
    } else {
        console.log("inputLoanTypeElement is not valid:", inputLoanTypeElement);
    }

    if (currency && currency.options && currency.selectedIndex >= 0) {
        currencyValue = currency.options[currency.selectedIndex].text;
    } else {
        console.log("currency is not valid:", currency);
    }

        dataLayer.push({
            'event': 'application_submit',
            'method': typeMethod,
            'category': pageCategoryValue,
            'type_partner': typePartnerValue,
            'bank_name': partnerNameValue,
            'product_name': productNameValue,
            'page_name': pageNameValue,
            'type_product': typeProductValue,
            'type_sum': typeSumValue,
            'type_term': typeTermValue,
            'currency': currencyValue,
            'affiliation': affiliationValue
        });

        console.log('application_submit' + ' - ' + typeMethod + ' - ' + pageCategoryValue + ' - ' + typePartnerValue + ' - ' + partnerNameValue + ' - ' + productNameValue + ' - ' + pageNameValue + ' - ' + typeProductValue + ' - ' + typeSumValue + ' - ' + typeTermValue + ' - ' + currencyValue + ' - ' + affiliationValue);
    }

    broker_start(affiliation, pageName = '') {
        if (this.useGtm() === false) return;

        let affiliationValue = affiliation
        let pageNameValue = pageName

        dataLayer.push({
            "event": "broker_start",
            'page_name': pageNameValue,
            'affiliation': affiliationValue
        });

        console.log('broker_start' + ' - ' + pageNameValue + ' - ' + affiliationValue);
    }

    broker_account_statement() {
        if (this.useGtm() === false) return;

        dataLayer.push({
            "event": "broker_account_statement",
        });

        console.log('broker_account_statement');
    }

    broker_OTP() {
        if (this.useGtm() === false) return;

        dataLayer.push({
            "event": "broker_OTP",
        });

        console.log('broker_OTP');
    }

    broker_application_details(typeSum, typeTerm) {
        if (this.useGtm() === false) return;

        if (!typeSum || !typeTerm) return;

        dataLayer.push({
            "event": "broker_application_details",
            "type_sum": typeSum,
            "type_term": typeTerm
        });

        console.log('broker_application_details' + ' - ' + typeSum + ' - ' + typeTerm);
    }

// Передавать код в момент успешной отправки заявки партнеру
    broker_sending_partner() {
        if (this.useGtm() === false) return;

        dataLayer.push({
            "event": "broker_sending_partner",
        });

        console.log('broker_sending_partner');
    }
}
