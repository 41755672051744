const inputs = document.querySelectorAll('.cont_ipt input');

  window.addEventListener('load', () => {

    inputs.forEach(e => {
      setTimeout(full(e), 6000);
    });
  });

  

  function full(i){
    if (i.value !== '') {
      i.nextElementSibling.classList.add('valid');
    }
  };

  inputs.forEach(input => {
    const placeholder = input.nextElementSibling;

    input.setAttribute('maxlength', '10');

    input.addEventListener('click', () => {
      placeholder.classList.add('valid');
      if (input.value.trim() !== '' && placeholder.nextElementSibling) {
        placeholder.nextElementSibling.classList.add('valid');   
      }
    });
    
});






