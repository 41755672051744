const inputs = document.querySelectorAll('.ipt-typeSum');

inputs.forEach(input => {
  input.addEventListener('input', () => {
    if (input.value !== '0') {
      input.value = input.value.replace(/[^\d]/g, '');

      const formattedValue = input.value.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1 ");
      input.value = formattedValue;
    }
  });
  
  input.setAttribute('maxlength', '10');


  window.addEventListener('load', () => {
    bitDepth(input);
  });

});

function bitDepth(input){
  if (input.value !== '0') {
    input.value = input.value.replace(/[^\d]/g, '');

    const formattedValue = input.value.replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, "$1 ");
    input.value = formattedValue;
  }
};


