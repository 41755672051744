document.addEventListener('DOMContentLoaded', () => {

    const downBtn = document.querySelector('.btn-down');
    const target = document.querySelector('.targetBtn');

    if (downBtn) {
        downBtn.addEventListener('click', () => {
            const topOffset = target.getBoundingClientRect().top + window.pageYOffset;

            window.scrollTo({
                top: topOffset,
                behavior: 'smooth'
            });
        });
    }
});
