var typeBlocks = document.querySelectorAll('.currency_type');
  typeBlocks.forEach(typeBlock => {
    var label = typeBlock.querySelectorAll('.currency_lable');

      label.forEach(function(lable) {
        lable.addEventListener('click', function(event) {
          event.preventDefault();
          var elements = typeBlock.querySelectorAll('.selected_blue');
          elements.forEach(function(element) {
            element.classList.remove('selected_blue');
          });
          lable.classList.add('selected_blue');
        });
    });
});

var transactionTypeBlock = document.querySelector('.buy_or_sell');
var type = transactionTypeBlock ? transactionTypeBlock.querySelectorAll('.transaction_type') : null;

if (type) {
    type.forEach(function(types) {
        types.addEventListener('click', function(event) {
            event.preventDefault();
            var selectedType = transactionTypeBlock.querySelector('.selected_white_bg');
            if (selectedType) {
                selectedType.classList.remove('selected_white_bg');
            }
            this.classList.add('selected_white_bg');
        });
    });
}











