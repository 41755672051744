document.querySelector(".arrow-up").addEventListener("click", function() {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
});


window.addEventListener("scroll", function() {
  if (window.pageYOffset >= 800) {
    document.querySelector(".arrow-up").classList.remove("arrow-none");
  } else if (window.pageYOffset < 200) {
    document.querySelector(".arrow-up").classList.add("arrow-none");
  }
});
