export default function analytics() {
    const metaTag = document.querySelector('meta[name="ga-measurement-id"]');

    if (!metaTag) {
        console.error('Meta-тег GA_MEASUREMENT_ID отсутствует.');
        return;
    }

    const measurementId = metaTag.getAttribute('content');

    if (!measurementId) {
        console.error('GA_MEASUREMENT_ID пустой.');
        return;
    }

    if (typeof gtag === 'undefined') {
        console.error('gtag не инициализирован');
        return;
    }

    gtag('get', measurementId, 'client_id', (clientId) => {
        if (clientId) {
            fetch('/api/ga/store-client-id', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ client_id: clientId }),
            })
                .then(response => response.json())
                .then(data => console.log('Client ID сохранен:', data))
                .catch(error => console.error('Ошибка:', error));
        } else {
            console.error('Не удалось получить client_id');
        }
    });
}
