import IMask from 'imask';

document.addEventListener('DOMContentLoaded', () => {

    const phoneElement = document.getElementById('phone');

    if (phoneElement) {
        IMask(
            phoneElement,
            {
                mask: '+{7} (000) 000-00-00',
                prepare: (chars, masked) => {
                    if (chars) {
                        let value = chars.replace(/\D/g, '');
                        if (value.length === 10) {
                            return '7' + value;
                        }
                    }
                    return chars;
                }
            }
        );
    }
});
